import React from "react"
import Layout from "../components/layout"

function Support() {
  return(
    <Layout>
      <div className="support-header">
        <div className="container">
          <div className="support-heading-wrapper">
            <h1>Our products can be purchased from the dealer network island wide or from through online at

              <a href="http://www.tools.lk/" className="clr-red" rel="nofollow" target="_blank"> www.tools.lk </a>
              and  <a href="https://www.daraz.lk/shop/colombo-tools-pvt-ltd" rel="nofollow" className="clr-red" target="_blank"> daraz.lk </a> </h1>
          </div>
        </div>
      </div>

      <div className="support-body container mt-4 mb-4">
        <div className="row">
          {/* <div className="col-md-8" >

            <section id="generalSection">
              <h3>General</h3>
              <div id="accordion1">

                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link" data-toggle="collapse" data-target="#g1"
                              aria-expanded="true" aria-controls="collapseOne">
                        Is this service is available in other country?
                      </button>
                    </h5>
                  </div>

                  <div id="g1" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion1">
                    <div className="card-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
                      wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                      eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
                      assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
                      sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                      farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                      labore sustainable VHS.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#g2"
                              aria-expanded="false" aria-controls="collapseTwo">
                        Do you have warranty for all the products?
                      </button>
                    </h5>
                  </div>
                  <div id="g2" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion1">
                    <div className="card-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
                      wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                      eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
                      assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
                      sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                      farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                      labore sustainable VHS.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#g3"
                              aria-expanded="false" aria-controls="collapseThree">
                        Is it possible to return the tool if I don't like it?
                      </button>
                    </h5>
                  </div>
                  <div id="g3" className="collapse" aria-labelledby="headingThree" data-parent="#accordion1">
                    <div className="card-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
                      wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                      eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
                      assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
                      sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                      farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                      labore sustainable VHS.
                    </div>
                  </div>
                </div>

              </div>
            </section>

            <section id="orderSection" className="mt-4">
              <h3>Order</h3>
              <div id="accordion">

                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link" data-toggle="collapse" data-target="#o1"
                              aria-expanded="true" aria-controls="collapseOne">
                        How can I order tool?
                      </button>
                    </h5>
                  </div>

                  <div id="o1" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                    <div className="card-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
                      wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                      eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
                      assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
                      sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                      farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                      labore sustainable VHS.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#o2"
                              aria-expanded="false" aria-controls="collapseTwo">
                        I don't see an option for buy tools?
                      </button>
                    </h5>
                  </div>
                  <div id="o2" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                    <div className="card-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
                      wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                      eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
                      assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
                      sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                      farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                      labore sustainable VHS.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#o3"
                              aria-expanded="false" aria-controls="collapseThree">
                        Is it possible get the tool to door step
                      </button>
                    </h5>
                  </div>
                  <div id="o3" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                    <div className="card-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
                      wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                      eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
                      assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
                      sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                      farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                      labore sustainable VHS.
                    </div>
                  </div>
                </div>

              </div>
            </section>

            <section id="paymentSection" className="mt-4">
              <h3>Payment</h3>
              <div id="accordion">
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree"
                              aria-expanded="false" aria-controls="collapseThree">
                        How to make the payment?
                      </button>
                    </h5>
                  </div>
                  <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                    <div className="card-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
                      wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                      eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
                      assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
                      sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                      farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                      labore sustainable VHS.
                    </div>
                  </div>
                </div>

              </div>
            </section>

          </div>  */}

          <div className="col-md-4 row mx-auto">

            <div className="d-flex flex-column align-items-center">
              <span className="fas fa-truck delivery-text icon-service"></span>
              <p className='text-center mt-2 mt-lg-0 delivery-text tittle-service'>
                Delivery
              </p>
              <p className='text-center content-service'>
                We provide faster and efficient covering the entire nation. Orders are processed on time to make sure that orders reach as faster as possible.
              </p>
            </div>

            <div className="d-flex flex-column align-items-center">
              <span className="fas fa-graduation-cap product-text icon-service"></span>
              <p className='text-center product-text tittle-service'>
                Product knowledge
              </p>
              <p className='text-center content-service'>
                Our technical sales staffs members are imparted with product knowledge on latest and state of the art tools and machineries thus make your purchases meaningful.
              </p>
            </div>

            <div className="d-flex flex-column align-items-center">
              <span className="fas fa-shield waranty-text icon-service"></span>
              <p className='text-center mt-2 mt-lg-0 waranty-text tittle-service'>
                Warranty
              </p>
              <p className='text-center content-service'>
                We assure warranty for our products with no additional charges to prevent our customers from buying cheaper non-warranty products.
              </p>
            </div>

            <div className="d-flex flex-column align-items-center">
              <span className="fas fa-support knowledge-text icon-service"></span>
              <p className='text-center knowledge-text tittle-service'>
                After sales service
              </p>
              <p className='text-center content-service'>
                Machineries and tools are provided with after sales services with a comprehensive and instantly available spares and accessories
              </p>
            </div>

            <div className="d-flex flex-column align-items-center">
              <span className="fas fa-hand-peace-o customer-text icon-service"></span>
              <p className='text-center customer-text tittle-service'>
                Repair and maintenance
              </p>
              <p className='text-center content-service'>
                Fine Enterprises has its own fully-fledged service department to ensure rapid repair services.
              </p>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
}
export default Support
